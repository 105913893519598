import { Component } from "react";

class CollectionTwo extends Component {
    render() { 
        return (
            <section id="attribute" className="collection bg--cover padding-top padding-bottom" style={{backgroundImage: "url(/assets/images/attribute/bg.jpg)"}}>
                <div className="container">
                    <div className="collection__wrapper">
                        <div className="row flex-lg-row-reverse align-items-center g-5">
                            <div className="col-lg-6">
                                <div className="collection__thumb d-flex align-items-center g-20">
                                    <img src="assets/images/attribute/01.png" alt="Attribute Images" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="collection__content">
                                    <h2 className="text-uppercase mb-4"><span className="color--gradient-y">Meww</span> Attributes
                                    </h2>
                                    <p>In 1989, the original Charging Meww sculpture was created by Italian artist Lorem Ipsum Roanbso and placed on Wall Street. It was meant to symbolize the aggressive financial optimism and prosperity of the stock market.</p>
                                    <p>After the success of the original, its artist was commissioned to create multiple variations of the Meww. Each sold to private collectors all over the world.</p>
                                    <p className="mb-5">Financial optimism and aggressive growth has moved from Wall Street… and into the Metaverse.</p>
                                    <a href="#" className="default-btn">View On Opensea</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default CollectionTwo;