import { Component } from "react";

import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

const title = "Roadmap";
const desc = "Here is what we have planned for this project.";


class Roadmap extends Component {
    render() { 
        return (
            <section className="roadmap padding-top padding-bottom" id="roadmap">
                <div className="container">
                    <div className="section-header d-flex justify-content-between align-items-center aos-init aos-animate"
                        data-aos="fade-up" data-aos-duration="1000">
                        <div className="section-header__content text-start">
                            <h2> <span className="color--gradient-y"> {title}</span> </h2>
                            <p>{desc}</p>
                        </div>
                        <div className="roadmap__nav d-flex g-10">
                            <div className="roadmap__prev"> <i className="icofont-rounded-left"></i> </div>
                            <div className="roadmap__next"> <i className="icofont-rounded-right"></i></div>
                        </div>
                    </div>
                </div>
                <div className="roadmap__wrapper">
                    <div className="container-fluid">
                        <div className="swiper roadmap__slider">
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={1}
                                loop={'true'}
                                autoplay={{
                                    delay: 3000,
                                }}
                                navigation={{
                                    prevEl: '.roadmap__prev',
                                    nextEl: '.roadmap__next',
                                }}
                                speed={5000}
                                modules={[Autoplay, Navigation]}
                                breakpoints={{
                                    0: {
                                        width: 0,
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        width: 768,
                                        slidesPerView: 2,
                                    },
                                    1200: {
                                        width: 1200,
                                        slidesPerView: 2.5,
                                    },
                                }}
                                >
                                <SwiperSlide>
                                    <div className="roadmap__item aos-animate aos-init" data-aos="fade-left" data-aos-duration="1000">
                                        <div className="roadmap__inner">
                                            <div className="roadmap__content">
                                                <h4>1.Launch Initiaed</h4>
                                                <p>Our number one priority is keeping our meww NFTs quality worthy of our artist’s name.</p>
                                                <p>Preparations must be made ensure the Crypto Societys collection lives up to the same standard excellence  as any other art project by ral.</p>
                                                <p>Our number one priority is keeping our meww NFTs quality worthy of our artist’s name.</p>
                                                <p>Preparations must be made ensure the Crypto Societys collection lives up to the same standard excellence as any other art project by yal.</p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="roadmap__item aos-animate aos-init" data-aos="fade-left" data-aos-duration="1000">
                                        <div className="roadmap__inner">
                                            <div className="roadmap__content">
                                                <h4>2. Physical Merch & Live Events</h4>
                                                <p>Our number one priority is keeping our meww NFTs quality worthy of our artist’s name.</p>
                                                <p>Preparations must be made ensure the Crypto Societys collection lives up to the same standard excellence  as any other art project by ral.</p>
                                                <p>Our number one priority is keeping our meww NFTs quality worthy of our artist’s name.</p>
                                                <p>Preparations must be made ensure the Crypto Societys collection lives up to the same standard excellence as any other art project by yal.</p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="roadmap__item aos-animate aos-init" data-aos="fade-left" data-aos-duration="1000">
                                        <div className="roadmap__inner">
                                            <div className="roadmap__content">
                                                <h4>3. The Crypto Meww</h4>
                                                <p>Our number one priority is keeping our meww NFTs quality worthy of our artist’s name.</p>
                                                <p>Preparations must be made ensure the Crypto Societys collection lives up to the same standard excellence  as any other art project by ral.</p>
                                                <p>Our number one priority is keeping our meww NFTs quality worthy of our artist’s name.</p>
                                                <p>Preparations must be made ensure the Crypto Societys collection lives up to the same standard excellence as any other art project by yal.</p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="roadmap__item aos-animate aos-init" data-aos="fade-left" data-aos-duration="1000">
                                        <div className="roadmap__inner">
                                            <div className="roadmap__content">
                                                <h4>4. Metaverse</h4>
                                                <p>Our number one priority is keeping our meww NFTs quality worthy of our artist’s name.</p>
                                                <p>Preparations must be made ensure the Crypto Societys collection lives up to the same standard excellence  as any other art project by ral.</p>
                                                <p>Our number one priority is keeping our meww NFTs quality worthy of our artist’s name.</p>
                                                <p>Preparations must be made ensure the Crypto Societys collection lives up to the same standard excellence as any other art project by yal.</p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="roadmap__item aos-animate aos-init" data-aos="fade-left" data-aos-duration="1000">
                                        <div className="roadmap__inner">
                                            <div className="roadmap__content">
                                                <h4>5. Upcoming</h4>
                                                <p>Our number one priority is keeping our meww NFTs quality worthy of our artist’s name.</p>
                                                <p>Preparations must be made ensure the Crypto Societys collection lives up to the same standard excellence  as any other art project by ral.</p>
                                                <p>Our number one priority is keeping our meww NFTs quality worthy of our artist’s name.</p>
                                                <p>Preparations must be made ensure the Crypto Societys collection lives up to the same standard excellence as any other art project by yal.</p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Roadmap;