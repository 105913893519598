import { Component } from "react";


class Artist extends Component {
    render() { 
        return (
            <section id="artist" className="artist padding-top padding-bottom bg--cover" style={{backgroundImage: "url(/assets/images/artist/bg.jpg)"}}>
                <div className="container">
                    <div className="artist__wrapper">
                        <div className="row flex-lg-row-reverse g-5">
                            <div className="col-lg-6">
                                <div className="artist__thumb" data-aos="fade-up" data-aos-duration="1000">
                                    <img src="assets/images/artist/01.png" alt="Artist Image" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="artist__content" data-aos="fade-up" data-aos-duration="1500">
                                    <h2 className="mb-4"><span className="color--gradient-y d-block">Artist</span> Smith Alex</h2>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempore ratione natus soluta accusamus expedita harum aliquid ullam? Ex, fugali Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet consectetur adip sicing elit. Modi, animi?</p>
                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum, cupiditate atque! Ad veniam et dignissimos asperiores natus dolores, odio hic. Lore ipsum dolor sit amet.</p>
                                    <p className="mb-5">Entering The Herox Society means joining an family full of peoples who believe in the future of Cryptocurrences and the Blockchain technology.</p>
                                    <a href="#" className="default-btn">
                                        <span>More About The Artist</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Artist;