import {BrowserRouter } from "react-router-dom";

import 'swiper/css';


import AosInit from "./component/layout/aos";
import Banner from "./component/layout/banner";
import Footer from "./component/layout/footer";
import Header from "./component/layout/header";
import ScrollToTop from "./component/layout/scrolltop";
import Artist from "./component/section/artist";
import Collection from "./component/section/collection";
import CollectionTwo from "./component/section/collection-2";
import Faq from "./component/section/faq";
import Roadmap from "./component/section/roadmap";
import Team from "./component/section/team";

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<AosInit />
			<Header />
			<Banner />
			<Collection />
			<CollectionTwo />
			<Roadmap />
			<Team />
			<Faq />
			<Artist />
			<Footer />
		</BrowserRouter>
	);
}

export default App;
