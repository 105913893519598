import { Component } from "react";

const titleOne = "Meww";
const titleTwo = "NFT items";
const desc = "6666 Unique Undercover Creatures, Starting Minting The 12th of December";
const btnText = "Mint Now";

class Banner extends Component {
    render() { 
        return (
            <section id="home" className="banner" style={{backgroundImage: "url(/assets/images/banner/banner-bg.jpg)"}}>
                <div className="container">
                    <div className="banner__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-7">
                                <div className="banner__content" data-aos="fade-up" data-aos-duration="2000">
                                    <h1 className="text-uppercase"> <span className="color--gradient-y">{titleOne}</span> <br /> {titleTwo}</h1>
                                    <p>{desc}</p>
                                    <a href="#" className="default-btn">{btnText}</a>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="banner__thumb">
                                    <img src="assets/images/banner/01.png" alt="NFT Item Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Banner;