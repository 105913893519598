import { Component } from "react";

const title = "The Team";
const desc = "Here is the team that is managing this project with the Artist. Yap. Don't worry, we also have 15 team members taiing care of our Social medias,discord,marketing and more.";

let teamList = [
    {
        imgUrl: 'assets/images/team/01.jpg',
        imgAlt: 'Team Thumb',
        name: 'Johann Row',
        dige: 'UI/UX Designer',
    },
    {
        imgUrl: 'assets/images/team/02.jpg',
        imgAlt: 'Team Thumb',
        name: 'Robinor Uep',
        dige: 'NFT Marketer',
    },
    {
        imgUrl: 'assets/images/team/03.jpg',
        imgAlt: 'Team Thumb',
        name: 'jhon Doe',
        dige: 'The Developer',
    },
    {
        imgUrl: 'assets/images/team/04.jpg',
        imgAlt: 'Team Thumb',
        name: 'Sammlie tom',
        dige: '3d Artist',
    },
]

class Team extends Component {
    render() { 
        return (
            <section id="team" className="team padding-top padding-bottom bg--fixed" style={{backgroundImage: "url(/assets/images/team/bg.jpg)"}}>
                <div className="container">
                    <div className="section-header aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <div className="section-header__content">
                            <h2> <span className="color--gradient-y d-block"> {title}</span></h2>
                            <p>{desc}</p>
                        </div>
                    </div>
                    <div className="team__wrapper">
                        <div className="row justify-content-center g-4">
                            {teamList.map((val, i) => (
                                <div className="col-lg-3 col-md-6" key={i}>
                                    <div className="team__item" data-aos="flip-right" data-aos-duration="1000">
                                        <div className="team__inner">
                                            <div className="team__thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="team__content">
                                                <h5><a href="#">{val.name}</a></h5>
                                                <p>{val.dige}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Team;