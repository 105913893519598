import { Component } from "react";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';


let collectionList = [
    {
        imgUrl: 'assets/images/collection/01.png',
        imgAlt: 'NFT Image',
    },
    {
        imgUrl: 'assets/images/collection/02.png',
        imgAlt: 'NFT Image',
    },
    {
        imgUrl: 'assets/images/collection/03.png',
        imgAlt: 'NFT Image',
    },
]

class Collection extends Component {
    render() { 
        return (
            <section className="collection padding-top padding-bottom" id="collection">
                <div className="container">
                    <div className="collection__wrapper">
                        <div className="row align-items-center g-5">
                            <div className="col-lg-6">
                                <div className="collection__thumb align-items-center row g-4 justify-content-center">
                                    <div className="col-xl-6">
                                        <div className="swiper collection__thumb-slider-1">
                                        <Swiper
                                            slidesPerView={1}
                                            spaceBetween={30}
                                            loop={'true'}
                                            autoplay={{
                                                delay: 1,
                                                disableOnInteraction: false,
                                            }}
                                            speed={5000}
                                            modules={[Autoplay]}
                                            >
                                            {collectionList.map((val, i) => (
                                                <SwiperSlide key={i}>
                                                    <div className="collection__thumb-item">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 d-none d-xl-block">
                                        <div className="swiper collection__thumb-slider-2">
                                            <Swiper
                                                spaceBetween={30}
                                                slidesPerView={2}
                                                loop={'true'}
                                                autoplay={{
                                                    delay: 3000,
                                                    reverseDirection: true,
                                                    
                                                }}
                                                speed={5000}
                                                modules={[Autoplay]}
                                                >
                                                {collectionList.map((val, i) => (
                                                    <SwiperSlide key={i}>
                                                        <div className="collection__thumb-item">
                                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="collection__content">
                                    <h2 className="text-uppercase mb-4"><span className="color--gradient-y">7,777 Unique</span> Meww</h2>
                                    <p>The Crypto Meww Society is a collection of 7,777 unique Meww who unite together Ethereum Blockchain</p>
                                    <p>Each Meww is unique, based on hundreds of well-designed elements characterize a bullish crypto enthusiast.</p>
                                    <p className="mb-5">Entering The Meww Society means joining a family full of people who believe in the future Cryptocurrencies and the Blockchain technology.</p>
                                    <a href="#" className="default-btn">View On Opensea</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Collection;