import { Component } from "react";

class Faq extends Component {
    render() { 
        return (
            <section id="faq" className="faq padding-top padding-bottom">
                <div className="container">
                    <div className="section-header">
                        <div className="section-header__content">
                            <h2> <span className="color--gradient-y d-block"> FREQUENTLY</span>ASKED QUESTIONS</h2>
                        </div>
                    </div>
                    <div className="faq__wrapper">
                        <div className="row g-3">
                            <div className="col-lg-6">
                                <div className="accordion" id="faqAccordion2">
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up-right" data-aos-duration="1000">
                                                <div className="accordion__header" id="faq1">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody1"
                                                        aria-expanded="false" aria-controls="faqBody1">
                                                        01 When is the official launch? <span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody1" className="accordion-collapse collapse" aria-labelledby="faq1"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from real artist for afordable price That is why we chose mint price of 0.22 ETH (+ gas fee). If you are planning to hold your Bulls you will bee rewarded with FEE Meww approximatey 6-8 weeks after the official launch 02  What will the mint price be?.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up-right" data-aos-duration="1100">
                                                <div className="accordion__header" id="faq2">
                                                    <button className="accordion__button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#faqBody2" aria-expanded="true"
                                                        aria-controls="faqBody2">
                                                        02 What will the mint price be? <span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody2" className="accordion-collapse collapse show"
                                                    aria-labelledby="faq2" data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from real artist for afordable price That is why we chose mint price of 0.22 ETH (+ gas fee). If you are planning to hold your Bulls you will bee rewarded with FEE Meww approximatey 6-8 weeks after the official launch 02  What will the mint price be?.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up-right" data-aos-duration="1200">
                                                <div className="accordion__header" id="faq3">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody3"
                                                        aria-expanded="false" aria-controls="faqBody3">
                                                        03 How does the raffle work?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody3" className="accordion-collapse collapse" aria-labelledby="faq3"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                    We want provide our community with the best quality art from real artist for afordable price That is why we chose mint price of 0.22 ETH (+ gas fee). If you are planning to hold your Bulls you will bee rewarded with FEE Meww approximatey 6-8 weeks after the official launch 02  What will the mint price be?.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up-right" data-aos-duration="1300">
                                                <div className="accordion__header" id="faq4">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody4"
                                                        aria-expanded="false" aria-controls="faqBody4">
                                                        04 How do I get a FREE Meww?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody4" className="accordion-collapse collapse" aria-labelledby="faq4"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body"> We want provide our community with the best quality art from real artist for afordable price That is why we chose mint price of 0.22 ETH (+ gas fee). If you are planning to hold your Bulls you will bee rewarded with FEE Meww approximatey 6-8 weeks after the official launch 02  What will the mint price be?.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="accordion" id="faqAccordion2-2">
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up-left" data-aos-duration="1000">
                                                <div className="accordion__header" id="faq1-2">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody1-2"
                                                        aria-expanded="false" aria-controls="faqBody1-2">
                                                        05. When is the official launch? <span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody1-2" className="accordion-collapse collapse"
                                                    aria-labelledby="faq1-2" data-bs-parent="#faqAccordion2-2">
                                                    <div className="accordion__body"> We want provide our community with the best quality art from real artist for afordable price That is why we chose mint price of 0.22 ETH (+ gas fee). If you are planning to hold your Bulls you will bee rewarded with FEE Meww approximatey 6-8 weeks after the official launch 02  What will the mint price be?.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up-left" data-aos-duration="1100">
                                                <div className="accordion__header" id="faq2-2">
                                                    <button className="accordion__button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#faqBody2-2" aria-expanded="true"
                                                        aria-controls="faqBody2-2">
                                                        06. How Can I use Meww NFT? <span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody2-2" className="accordion-collapse collapse show"
                                                    aria-labelledby="faq2-2" data-bs-parent="#faqAccordion2-2">
                                                    <div className="accordion__body"> We want provide our community with the best quality art from real artist for afordable price That is why we chose mint price of 0.22 ETH (+ gas fee). If you are planning to hold your Bulls you will bee rewarded with FEE Meww approximatey 6-8 weeks after the official launch 02  What will the mint price be?.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up-left" data-aos-duration="1400">
                                                <div className="accordion__header" id="faq5-2">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody5-2"
                                                        aria-expanded="false" aria-controls="faqBody5-2">
                                                        07. How do I get whitelisted?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody5-2" className="accordion-collapse collapse"
                                                    aria-labelledby="faq5-2" data-bs-parent="#faqAccordion2-2">
                                                    <div className="accordion__body">We want provide our community with the best quality art from real artist for afordable price That is why we chose mint price of 0.22 ETH (+ gas fee). If you are planning to hold your Bulls you will bee rewarded with FEE Meww approximatey 6-8 weeks after the official launch 02  What will the mint price be?.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up-left" data-aos-duration="1500">
                                                <div className="accordion__header" id="faq6-2">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody6-2"
                                                        aria-expanded="false" aria-controls="faqBody6-2">
                                                        08. How can I use my NFT ?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody6-2" className="accordion-collapse collapse"
                                                    aria-labelledby="faq6-2" data-bs-parent="#faqAccordion2-2">
                                                    <div className="accordion__body"> We want provide our community with the best quality art from real artist for afordable price That is why we chose mint price of 0.22 ETH (+ gas fee). If you are planning to hold your Bulls you will bee rewarded with FEE Meww approximatey 6-8 weeks after the official launch 02  What will the mint price be?.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Faq;